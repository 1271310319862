/*
 * @Author: star945
 * @Date: 2023-01-30 14:10:48
 * @LastEditors: star945 609427499@qq.com
 * @LastEditTime: 2023-02-14 17:56:51
 * @FilePath: \ddmark-web\src\router\index.js
 * @Description:
 *
 */
import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "home",
    component: () => import("../views/home/index.vue"),
  },
  {
    path: "/aboutus",
    name: "aboutus",
    component: () => import("../views/about-us/index.vue"),
  },
  {
    path: "/contactus",
    name: "contactus",
    component: () => import("../views/contact-us/index.vue"),
  },{
    path: "/examples",
    name: "examples",
    component: () => import("../views/examples/index.vue"),
  },{
    path: "/ideaset",
    name: "home",
    component: () => import("../views/idea-set/index.vue"),
  },
  {
    path: "/partner",
    name: "partner",
    component: () => import("../views/partner/index.vue"),
  },
  {
    path: "/product",
    name: "product",
    component: () => import("../views/product/index.vue"),
  },
  {
    path: "/news",
    name: "news",
    component: () => import("../views/news/index.vue"),
  },
  {
    path: "/examples/detail",
    name: "examplesDetail",
    component: () => import("../views/examples/detail.vue"),
  },
  {
    path: "/news/detail",
    name: "newsDetail",
    component: () => import("../views/news/detail.vue"),
  },
  // {
  //   path: "/about",
  //   name: "about",
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () =>
  //     import(/* webpackChunkName: "about" */ "../views/AboutView.vue"),
  // },
];

const router = new VueRouter({
  mode: "history",
  scrollBehavior: () => ({y: 0}),
  base: process.env.BASE_URL,
  routes,
});

export default router;
