<!--
 * @Author: tianlu tianlu@diandongmark.com
 * @Date: 2023-01-31 12:59:42
 * @LastEditors: tianlu tianlu@diandongmark.com
 * @LastEditTime: 2023-02-20 10:02:45
 * @FilePath: \ddmark-web\src\components\Banner\index.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%A
-->
<template>
  <div class="banner is-fluid">
    <!-- <img class="banner-img" :src="bannerData.imgUrl" /> -->
    <div class="banner-content"
      :style="`background-image: url('${bannerData.imgUrl ? bannerData.imgUrl : imgBaseUrl + bannerData.bannerUrl}')`">
      <video src="../../assets/images/video.mp4" autoplay loop v-if="bannerData.type=='video'"></video>
      <div class="container" style="width:100%">
        <div class="banner-content-seize"></div>
        <div class="banner-content-left">
          <div class="banner-content-left-title">{{ bannerData.title }}</div>
          <div class="banner-content-left-remark">
            <p>{{ bannerData.describe }}</p>
          </div>
          <slot name="custom-content"></slot>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "banner",
  props: {
    bannerData: {
      type: Object,
      default() {
        return {}
      },
    },
  },
  data() {
    return {
      imgBaseUrl: process.env.VUE_APP_BASE_API
    }
  },
  created() { },

  mounted() { },

  methods: {},
}
</script>

<style lang="scss" scoped>
video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  object-fit: fill;
  outline: none;
  mix-blend-mode: darken;
}

.banner {
  position: relative;
  font-size: 0;

  &-img {
    display: block;
  }

  &-content {
    // position: absolute;
    background-repeat: no-repeat;
    max-height: 540px;
    //height: 28.125vw;
    height: 27.8vw;
    background-size: cover;
    display: flex;
    overflow: hidden;
    font-size: 0;

    &-seize {
      height: 2rem;
    }

    &-left {
      flex: 1;
      display: flex;
      flex-direction: column;
      justify-content: center;

      &-title {
        font-size: 2rem;
        font-weight: 500;
        color: #333333;
        line-height: 45px;
      }

      &-remark {
        font-size: 1rem;
        margin-top: 27px;
        font-weight: 400;
        color: #666666;
        white-space: pre-line;
      }
    }

    .container {
      display: flex;
      flex: 1;
      flex-direction: column;


    }

  }
}
</style>
