<!--
 * @Author: star945
 * @Date: 2023-02-10 15:30:35
 * @LastEditors: star945 609427499@qq.com
 * @LastEditTime: 2023-02-14 17:44:33
 * @FilePath: \ddmark-web\src\components\FixedBar\index.vue
 * @Description: 
 * 
-->
<template>
    <div class="fixed-bar">
        <div class="common-icon-box">
            <div class="phone-box">
                <div class="common-icon phone-icon">
                </div>
                <div class="common-fixed-box">
                    <div class="fixed-box-title">
                        售前咨询电话
                    </div>
                    <div class="popover-content">
                        <img width="23" height="23" src="../../assets/images/phone_icon.png" alt="">
                        <p>0312-5926477</p>
                    </div>
                    <div class="fixed-box-title">
                        售后客服电话
                    </div>
                    <div class="popover-content">
                        <img width="23" height="23" src="../../assets/images/phone_icon.png" alt="">

                        <p>0312-5926477</p>
                    </div>
                </div>
            </div>
            <div class="call-box">
                <div class="common-icon call-icon">
                </div>
                <div class="common-fixed-box" style="padding-bottom:20px">
                    <div class="fixed-box-title">开始售前沟通</div>
                    <div class="pop-over-content"
                        style="padding:24px 0 28px;display: flex; flex-direction: column;align-items: center;border-bottom: 1px dashed #ccc;">
                        <div class="call-info" style="display:flex;">
                            <img style="border-radius:50%" width="54" height="54"
                                src="@/assets/images/contactus/icon2.png" alt="">
                            <div class="call-content"
                                style="display:flex;flex-direction: column;justify-content:center;">
                                <p style="font-size: 14px;color: #333333;margin-bottom: 5px;">在线联系销售顾问，一对一解答</p>
                                <p class="call-service" style="color: #80d01c;font-size: 12px;">人工客服</p>
                            </div>
                        </div>
                        <a href="javascript:;" @click="goToservice"
                            style="display:inline-block;margin-top:35px;color:#fff;text-align: center;line-height: 36px;font-size: 14px;width: 120px;height: 36px;background: linear-gradient(270deg,#1b45e0 0%, #5478ef 1%);border-radius: 18px;">立即在线咨询</a>
                    </div>
                    <p class="common-bottom-text">在线咨询（售后）</p>
                    <p class="common-bottom-text">工单服务（5*8）</p>
                </div>
            </div>
            <div class="qrcode-box">
                <div class="common-icon qrcode-icon">
                </div>
                <div class="common-fixed-box"
                    style="text-align: center;flex-direction: column;padding: 24px 70px 36px;align-items: center;justify-content: center">
                    <img width="160" height="160" src="@/assets/images/codebar-wx.png" alt="">
                    <div class="popover-text">点动企业微信</div>
                </div>
            </div>
        </div>
        <div class="other-icon">
            <div class="common-icon up2top-icon" @click="toTop">
            </div>
        </div>
    </div>
</template>

<script>
export default {
    created() {
        document.addEventListener('click', (e) => {
            if (!this.$el.contains(e.target)) {
                var x = document.querySelectorAll(".is-actived-fixed")
                if (x && x.length > 0) {
                    x[0].classList.remove('is-actived-fixed')
                }
            }
        })
    },
    methods: {
        popShow(e) {
            var x = document.querySelectorAll(".is-actived-fixed")
            if (x && x.length > 0) {
                x[0].classList.remove('is-actived-fixed')
            }
            e.target.parentElement.classList.add('is-actived-fixed')
        },
        goToservice() {
            window.open("https://yzf.qq.com/xv/web/static/chat/index.html?sign=37ef9b97db260a97204c9dba1ee7e76a47e2cee3ed12235a1c4f82c67a6de820a46e4a56b1644d7045bea4665e84e3c4d8e058fd", "_blank")
        },
        toTop() {
            document.documentElement.scrollTop = 0
        }
    },
}
</script>

<style lang="scss" scoped>
.fixed-bar {

    position: fixed;
    bottom: 30%;
    right: 0;
    cursor: pointer;

    .common-icon-box {
        width: 60px;
        height: 180px;
        background: #ffffff;
        border-radius: 30px;
        box-shadow: -2px 1px 3px 0px rgba(0, 0, 0, 0.14);
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        overflow: hidden;

        >div {
            flex: 1;
            width: 100%;

            &.is-actived-fixed {
                .common-fixed-box {
                    display: block;

                }
            }

            .common-fixed-box {
                position: absolute;
                width: 300px;
                border-radius: 12px;
                transform: translate(-100%,-50%);
                background-color: #fff;
                z-index: 999;
                display: none;
                overflow: hidden;
                border-top: 5px solid #1b45e0;
                padding: 18px 24px 0;
                box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.10);

                .fixed-box-title {
                    font-size: 16px;
                    font-weight: 600;
                    text-align: left;
                    color: #333333;
                    line-height: 22px;
                    padding-left: 10px;
                    position: relative;

                    &::before {
                        content: '';
                        width: 4px;
                        height: 18px;
                        background: #1b45e0;
                        position: absolute;
                        top: 50%;
                        left: 0;
                        margin-top: -9px;


                    }


                }

                .popover-content {
                    padding: 26px 0 36px;
                    display: flex;
                    align-items: center;

                    p {
                        padding-left: 16px;
                        color: #1b45e0;
                        font-size: 24px;
                        font-weight: 700
                    }
                }
            }

            &.phone-box,
            &.call-box,
            &.qrcode-box {
                &:hover {
                    >.common-fixed-box {
                        display: block;
                    }

                    background: linear-gradient(270deg, #1b45e0 0%, #5478ef 76%);

                    >div {
                        background-position: left bottom;
                    }
                }
            }


            .phone-icon {
                height: 100%;
                background-image: url('../../assets/images/phone.png');
                background-repeat: no-repeat;
                background-position: left top;
                background-size: 100%;

            }

            .call-icon {
                height: 100%;
                background-image: url('../../assets/images/call.png');
                background-repeat: no-repeat;
                background-position: left top;
                background-size: 100%;
            }

            .qrcode-icon {
                height: 100%;
                background-image: url('../../assets/images/qrcode.png');
                background-repeat: no-repeat;
                background-position: left top;
                background-size: 100%;
            }

        }
    }

    .other-icon {
        width: 60px;
        height: 60px;
        background: #ffffff;
        border-radius: 30px;
        box-shadow: -2px 1px 3px 0px rgba(0, 0, 0, 0.14);
        margin-top: 9px;

        .up2top-icon {
            width: 100%;
            height: 100%;
            background-image: url('../../assets/images/up2top.png');
            background-repeat: no-repeat;
            background-position: center center;
        }
    }

    .common-bottom-text {
        color: #666666;
        font-size: 14px;
        padding-top: 18px;
    }

    .call-service {
        position: relative;
        padding-left: 20px;

        &::before {
            content: '';
            width: 8px;
            height: 8px;
            background: #80d01c;
            position: absolute;
            border-radius: 50%;
            top: 50%;
            left: 0;
            margin-top: -4px;
        }
    }

    .call-box {
        border-top: 1px solid #f5f5f5;
        border-bottom: 1px solid #f5f5f5;

    }
}
</style>