<!--
 * @Author: star945
 * @Date: 2023-01-30 14:10:48
 * @LastEditors: star945 609427499@qq.com
 * @LastEditTime: 2023-02-14 14:45:55
 * @FilePath: \ddmark-web\src\App.vue
 * @Description:
 *
-->
<template>
  <div id="app">
    <NavBar></NavBar>
    <transition name="fade-transform" mode="out-in">
      <router-view />
    </transition>
    <FooterView></FooterView>
    <FixedBar/>
  </div>
</template>
<script>
import NavBar from "@/components/NavBar"
import FooterView from "@/components/Footer"
import FixedBar from "@/components/FixedBar"

export default {
  name: 'App',
  components: {
    NavBar,
    FixedBar,
    FooterView,
  },
  metaInfo() {
    return {
      title: this.$store.state.settings.dynamicTitle && this.$store.state.settings.title,
      titleTemplate: title => {
        return title ? `${title} - ${process.env.VUE_APP_TITLE}` : process.env.VUE_APP_TITLE
      }
    }
  }
}
</script>
<style lang="scss">
#app {
  font-family: '微软雅黑', Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #333333;
}

body.has-navbar-fixed-top{
  padding-top: 0 !important;
}

@media screen and (min-width: 1620px) {
  .container:not(.is-max-desktop):not(.is-max-widescreen) {
    max-width: 1488px !important;
  }
}


</style>
