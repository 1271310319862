import request from '@/utils/request'
//Banner数据
export function getProductDetail(id) {
    return request({
        url: `/front/product/${id}`,
        method: 'get',
    })
}

export function getProductList(params) {
    return request({
        url: `/front/product/list`,
        method: 'get',
        params
    })
}
