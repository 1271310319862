/*
 * @Author: star945
 * @Date: 2023-01-30 16:32:02
 * @LastEditors: star945 609427499@qq.com
 * @LastEditTime: 2023-01-30 16:33:52
 * @FilePath: \ddmark-web\src\store\modules\settings.js
 * @Description: 
 * 
 */
const state = {
    title: '',
    dynamicTitle: true
}
const mutations = {
    CHANGE_SETTING: (state, { key, value }) => {
        if (state.hasOwnProperty(key)) {
            state[key] = value
        }
    }
}
const actions = {
    // 修改布局设置
    changeSetting({ commit }, data) {
        commit('CHANGE_SETTING', data)
    },
    // 设置网页标题
    setTitle({ commit }, title) {
        state.title = title
    }
}

export default {
    namespaced: true,
    state,
    mutations,
    actions
}
