/*
 * @Author: star945
 * @Date: 2023-01-30 14:10:48
 * @LastEditors: tianlu tianlu@diandongmark.com
 * @LastEditTime: 2023-02-09 22:00:49
 * @FilePath: \ddmark-web\src\main.js
 * @Description:
 *
 */
import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import Buefy from "buefy";
import VueMeta from 'vue-meta'

import "buefy/dist/buefy.css";

import "@/assets/styles/custom.scss";

Vue.use(Buefy);
Vue.use(VueMeta)
import Banner from "@/components/Banner"
Vue.component('Banner', Banner)
import CommonModule from "@/components/CommonModule"
Vue.component('CommonModule', CommonModule)
Vue.prototype.imgBaseUrl = process.env.VUE_APP_BASE_API;
Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
