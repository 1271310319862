<!--
 * @Author: star945
 * @Date: 2023-01-30 15:39:27
 * @LastEditors: tianlu tianlu@diandongmark.com
 * @LastEditTime: 2023-02-20 17:24:51
 * @FilePath: \ddmark-web\src\components\Footer\index.vue
 * @Description: 
 * 
-->
<template>
    <footer class="footer">
        <div class="container footer-container has-text-grey m-top-2">
            <div class="columns is-mobile is-multiline is-size-6 is-6">
                <div class="column is-8-desktop is-12-tablet is-12-mobile footer-listing">
                    <div class="columns is-mobile is-multiline">
                        <div class="column is-3-widescreen is-6-tablet is-12-mobile">
                            <p class="has-text-weight-medium title is-6 mb-4 has-text-white-bis">
                                产品中心
                            </p>
                            <p><a href="javascript:;" rel="noopener noreferrer">一站式数据分析平台</a></p>
                            <p><a href="javascript:;" rel="noopener noreferrer">自助式数据分析平台</a></p>
                            <p><a href="javascript:;" rel="noopener noreferrer">智能数据问答平台</a></p>
                            <p><a href="javascript:;" rel="noopener noreferrer">智能数据治理平台</a></p>
                        </div>
                        <div class="column is-3-widescreen is-6-tablet is-12-mobile">
                            <p class="has-text-weight-medium title is-6 has-text-white-bis mb-4">
                                解决方案
                            </p>
                            <p><a href="javascript:;" rel="noopener noreferrer">数据库运维解决方案</a></p>
                            <p><a href="javascript:;" rel="noopener noreferrer">运维监控管理方案</a></p>
                            <p><a href="javascript:;" rel="noopener noreferrer">智慧城市</a></p>
                            <p><a href="javascript:;" rel="noopener noreferrer">智慧政务</a></p>
                        </div>
                        <div class="column is-3-widescreen is-6-tablet is-12-mobile">
                            <p class="has-text-weight-medium title is-6 mb-4 has-text-white-bis">
                                关于我们
                            </p>
                            <div class="columns is-multiline is-mobile" style="margin-top:0;">
                                <div class="column is-6" style="padding:0 0.75rem;">
                                    <p><a href="/aboutus" rel="noopener noreferrer">公司简介</a></p>
                                </div>
                                <div class="column is-6" style="padding:0 0.75rem;">
                                    <p><a href="/partner" rel="noopener noreferrer">联系我们</a></p>
                                </div>
                                <div class="column is-6" style="padding:0 0.75rem;">
                                    <p><a href="/aboutus?menu=3" rel="noopener noreferrer">资质荣誉</a></p>
                                </div>
                                <div class="column is-6" style="padding:0 0.75rem;">
                                    <p><a href="javascript:;" rel="noopener noreferrer">加入我们</a></p>
                                </div>
                                <div class="column is-6" style="padding:0 0.75rem;">
                                    <p><a href="/aboutus?menu=2" rel="noopener noreferrer">发展历程</a></p>
                                </div>
                                <div class="column is-6" style="padding:0 0.75rem;">
                                    <p><a href="/news" rel="noopener noreferrer">新闻资讯</a></p>
                                </div>
                            </div>

                        </div>
                        <div class="column is-3-widescreen is-6-tablet is-12-mobile">
                            <p class="has-text-weight-medium title is-6 mb-4 has-text-white-bis">
                                合作发展
                            </p>
                            <p><a rel="noopener noreferrer">售后服务：service@axureux.com</a></p>
                            <p><a rel="noopener noreferrer">商务合作：mkt@axureux.com</a></p>
                            <p><a rel="noopener noreferrer">工作时间：9:00--18:00(工作日)</a></p>
                        </div>
                    </div>
                </div>
                <div class="column is-4-desktop is-12-tablet is-12-mobile" style="padding-left:5rem">
                    <div class="columns is-mobile is-multiline mt-5">
                        <div
                            class="column tags is-6-widescreen is-6-desktop is-6-tablet is-12-mobile has-text-centered ">
                            <div class="card-box">
                                <img src="@/assets/images/codebar-wx.png" alt="Placeholder image">
                                <div class="has-text-centered mt-2">
                                    微信公众号
                                </div>
                            </div>
                        </div>
                        <div
                            class="column tags is-6-widescreen is-6-desktop is-6-tablet is-12-mobile has-text-centered ">
                            <div class="card-box">
                                <img src="@/assets/images/codebar-tt.png" alt="Placeholder image">
                                <div class="has-text-centered mt-2">
                                    头条
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="has-text-centered" style="font-size: 12px;color: #cccccc;"> @ Copyright diandongmark.com2020.All Rights Reserved 备案号 冀ICP备16018121号-4 </div>

        </div>
    </footer>
</template>

<script>
export default {

}
</script>

<style lang="scss" scoped>
.footer {
    $footer-background-color: #333333;
    background-color: $footer-background-color;
    padding-bottom:2rem;

    a {
        color: #CCCCCC;
        font-size: 14px;
    }

    .footer-listing p {
        padding: 0.4rem 0;
        color: #CCCCCC;
    }

    .has-text-centered{
        color: #ffffff;
    }
}
</style>