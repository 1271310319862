<!--
 * @Author: star945
 * @Date: 2023-01-30 15:40:58
 * @LastEditors: tianlu tianlu@diandongmark.com
 * @LastEditTime: 2023-02-20 10:56:39
 * @FilePath: \ddmark-web\src\components\NavBar\index.vue
 * @Description: 
 * 
-->
<template>
  <div>
    <b-navbar
      centered
      close-on-click
      :class="{ 'white-navbar': !isTop }"
      wrapper-class="container"
      fixed-top
    >
      <template #brand>
        <b-navbar-item tag="router-link" :to="{ path: '/' }">
          <img src="../../assets/images/logo.png" alt="点动科技LOGO" />
        </b-navbar-item>
      </template>
      <template #start>
        <b-navbar-item
          tag="router-link"
          :class="{ 'navbar-actived': path == '/' }"
          :to="{ path: '/' }"
          :active="path == '/'"
          href="/"
        >
          首页
        </b-navbar-item>
        <b-navbar-item
          tag="router-link"
          :class="{ 'navbar-actived': path == '/aboutus' }"
          :to="{ path: '/aboutus' }"
          :active="path == '/aboutus'"
          href="/aboutus"
        >
          走进点动
        </b-navbar-item>
        <b-navbar-dropdown
          label="产品中心"
          tag="div"
          :class="{ 'navbar-actived': path == '/product' }"
          hoverable
          boxed
          wrapper-class="container"
        >
          <b-navbar-item tag="div">
            <div class="box1">
              <div class="container">
                <router-link
                  :to="`/product?id=${item.id}`"
                  class="item"
                  v-for="(item, index) in productList"
                  :key="index"
                >
                  {{ item.productName }}</router-link
                >
              </div>
            </div>
          </b-navbar-item>
        </b-navbar-dropdown>
        <b-navbar-dropdown
          label="解决方案"
          tag="div"
          :class="{ 'navbar-actived': path == '/ideaset' }"
          hoverable
          boxed
          wrapper-class="container"
        >
          <b-navbar-item tag="div">
            <div class="box1">
              <div class="container">
                <router-link
                  :to="`/ideaset?id=${item.id || 1}`"
                  class="item"
                  v-for="(item, index) in 1"
                  :key="index"
                >
                  政府政务</router-link
                >
              </div>
            </div>
          </b-navbar-item>
        </b-navbar-dropdown>
        <b-navbar-item
          tag="router-link"
          :class="{ 'navbar-actived': path == '/examples' }"
          :to="{ path: '/examples' }"
          :active="path == '/examples'"
          href="/examples"
        >
          典型案例
        </b-navbar-item>
        <b-navbar-item
          tag="router-link"
          :class="{ 'navbar-actived': path == '/partner' }"
          :to="{ path: '/partner' }"
          :active="path == '/partner'"
          href="/partner"
        >
          合作发展
        </b-navbar-item>

        <b-navbar-item
          tag="router-link"
          :class="{ 'navbar-actived': path == '/news' }"
          :to="{ path: '/news' }"
          :active="path == '/news'"
          href="/news"
        >
          新闻资讯
        </b-navbar-item>
        <b-navbar-item
          tag="router-link"
          :class="{ 'navbar-actived': path == '/contactus' }"
          :to="{ path: '/contactus' }"
          :active="path == '/contactus'"
          href="/contactus"
        >
          帮助与支持
        </b-navbar-item>
      </template>
      <template #end>
        <b-navbar-item tag="div">
          <div class="buttons">
            <a
              class="button is-primary"
              href="javascript:;"
              style="
                width: 90px;
                height: 36px;
                background: linear-gradient(270deg, #1b45e0 0%, #5478ef 80%);
                border-radius: 4px;
              "
            >
              GIS超图
            </a>
          </div>
        </b-navbar-item>
      </template>
    </b-navbar>
  </div>
</template>

<script>
import { getProductList } from "@/api/product";

export default {
  watch: {
    $route(route) {
      this.path = route.path;
      console.log(this.path);
    },
  },
  data() {
    return {
      path: "/",
      activeMenu: "/",
      isTop: true,
      productList: [],
    };
  },
  mounted() {
    window.addEventListener("scroll", this.handleScroll);
  },
  created() {
    this.getProductList();
  },
  methods: {
    handleScroll() {
      let scrollTop =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop;
      // 设置背景颜色的透明度
      if (scrollTop) {
        this.isTop = false; // scrollTop + 多少根据自己的需求设置
      } else if (scrollTop === 0) {
        this.isTop = true; // 设置回到顶部时，背景颜色为透明
      }
    },
    getProductList() {
      let queryParams = {
        pageSize: 10,
        pageNum: 1,
      };
      getProductList(queryParams).then((response) => {
        let { data } = response;
        this.productList = data.records;
      });
    },
    // 滚动之前重置
    beforeDestroy() {
      window.removeEventListener("scroll", this.handleScroll);
    },
  },
};
</script>

<style lang="scss" scoped>
.navbar {
  min-height: 4rem;
  background-color: rgba(255, 255, 255, 0.3);
  animation: changeTransparent 0.4s;
  &.white-navbar {
    background-color: rgba(255, 255, 255, 1);
    animation: changeWhite 0.4s;
  }
  &-brand,
  &-burger {
    min-height: 4rem;
  }

  &-item {
    & img {
      max-height: none;
      // margin-right: 10rem;
    }
  }

  &-menu {
    .navbar-start > .navbar-item {
      margin: 0 1rem;
      // padding: 0.5rem 0.75rem;
      position: relative;

      &:hover,
      &:focus,
      &:focus-within {
        background-color: transparent;
        color: #1d52f2;
      }

      &:hover::after {
        background: #1d52f2;
        animation: lineLong 1s;
      }

      &::after {
        content: " ";
        position: absolute;
        width: 100%;
        height: 3px;
        background: transparent;
        bottom: 0;
        left: 50%;
        transform: translateX(-50%);
        border-radius: 10px;
      }

      &.navbar-actived {
        color: #1d52f2 !important;

        // border-bottom:2px solid #1d52f2;
        &::after {
          background: #1d52f2;
        }
      }
    }
  }
}

nav {
  a {
    font-weight: 500;
    color: #333333;
  }
}

@keyframes lineLong {
  0% {
    width: 0;
  }

  100% {
    width: 100%;
  }
}

@keyframes  changeWhite{
  0% {
    background-color: rgba(255, 255, 255, 0.3);
  }

  100% {
    background-color: rgba(255, 255, 255, 1);
  }
}

@keyframes  changeTransparent{
  0% {
    background-color: rgba(255, 255, 255, 1);
  }

  100% {
    background-color: rgba(255, 255, 255, 0.3);
  }
}

// 自定义下拉弹窗
::v-deep .navbar-dropdown.is-boxed {
  position: fixed;
  top: 4rem;
  left: 0;
  right: 0;
  box-shadow: 0px 2px 8px 0px rgba(204, 204, 204, 0.5);
  border-radius: 0;
}

.box1 {
  width: 100vw;
  padding: 1.5rem 0;
  height: 280px;
  margin: 0 auto;

  .container {
    display: flex;
    justify-content: center;
  }

  .item {
    padding: 16px;
    font-size: 14px;

    &:hover {
      color: #1b45e0;
    }
  }
}

</style>
<style>
.navbar-item.has-dropdown:focus .navbar-link,
.navbar-item.has-dropdown:hover .navbar-link,
.navbar-item.has-dropdown.is-active .navbar-link {
  background-color: transparent !important;
  color: #1d52f2;
}

.navbar-menu .navbar-start > .navbar-item:hover,
.navbar-menu .navbar-start > .navbar-item:focus,
.navbar-menu .navbar-start > .navbar-item:focus-within {
  background-color: transparent;
  color: #1d52f2;
}

.navbar-link:not(.is-arrowless)::after {
  border-color: #333333 !important;
  border-width: 2px !important;
}
</style>
