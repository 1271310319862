<!--
 * @Author: tianlu tianlu@diandongmark.com
 * @Date: 2023-02-01 17:00:59
 * @LastEditors: tianlu tianlu@diandongmark.com
 * @LastEditTime: 2023-02-02 13:32:15
 * @FilePath: \ddmark-web\src\components\CommonModule\index.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <div class="common-module">
    <div class="common-title" :style="{color:titleColor}">{{ title }}</div>
    <div class="common-describe" v-if="describe">{{ describe }}</div>
    <slot class="content"></slot>
  </div>
</template>

<script>
export default {
  name: "commonModule",
  props: {
    title: {
      type: String,
      default:"",
    },
    describe:{
      type: String,
      default:"",
    },
    titleColor:{
      type: String,
      default:"#333333",
    }
  },
  data() {
    return {};
  },
  created() {},

  mounted() {},

  methods: {},
};
</script>

<style lang="scss" scoped>
.common-module {
  .common-title{
    font-size: 28px;
    font-weight: 600;
    line-height: 40px;
    text-align: center;
  }
  .common-describe{
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    color: #666666;
    text-align: center;
  }
}
</style>
