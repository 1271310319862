/*
 * @Author: star945
 * @Date: 2023-01-30 14:10:48
 * @LastEditors: star945 609427499@qq.com
 * @LastEditTime: 2023-01-30 16:38:20
 * @FilePath: \ddmark-web\src\store\index.js
 * @Description:
 *
 */
import Vue from "vue";
import Vuex from "vuex";
import settings from './modules/settings.js'


Vue.use(Vuex);

export default new Vuex.Store({
  modules:{
    settings
  },
  state: {},
  getters: {},
  mutations: {},
  actions: {},
});
